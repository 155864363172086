<template>
  <v-btn
    v-if="visible"
    :class="`${classes}`"
    :disabled="disabled"
    :title="lang('ChecklistBuilder.titles.toggle_content')"
    data-quick-button-action-view="toggle_content"
    type="button"
    small
  >
    <v-icon>
      mdi-code-tags
    </v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'ChecklistCellButtonTypeToggleContent',
  props: [
    'x',
    'y',
    'cell',
    'checklist',
    'state',
    'buttontype',
    'data'
  ],
  computed: {
    dataViewer() {
      const builder = this.data.ChecklistBuilder
      return builder.getCellDataViewer(this.checklist.index, this.x, this.y) || {}
    },
    classes() {
      return this.state.classes
    },
    visible() {
      const builder = this.data.ChecklistBuilder
      return !!builder.getSetting('ONLINE')
    },
    disabled() {
      return this.state.disabled
    }
  }
}
</script>
